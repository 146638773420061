.subocea-stats {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.subocea-stats .stats-item .img-container {
  height: 120px;
  width: 120px;
}

.subocea-stats .stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  margin-bottom: 1.5rem;
}

.subocea-stats .stats-item__description {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.subocea-stats .stats-item__description .text {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.subocea-stats .stats-item__description .number {
  font-size: 3rem;
  font-family: "Purple Purse", serif;
}
.subocea-stats .stats-item__description .number .sm {
  font-size: 2rem;
}
/* Payment safe */
.payment-safe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--primary-green);
  padding: 1.5rem;
  position: relative;
}
.payment-safe::before, .payment-safe::after {
  content: "";
    position: absolute;
    top: -28px;
    background-image: url(../images/new/footer-top-green-separator.png);
    background-repeat: repeat-x;
    height: 50px;
    width: 100%;
    background-size: contain;
    z-index: -1;
}
.payment-safe::after {
  top: calc(100% - 33px);
  background-image: url(../images/new/footer-bottom-green-separator.png);
}
.payment-safe h6 {
  margin-bottom: 0.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  font-size: 16px;
}
.payment-safe h6:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8ZM14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16Z' fill='white'/%3E%3C/svg%3E%0A");
  height: 1.5rem;
  width: 1.5rem;
}
.payment-safe .small {
  font-size: 10px;
  font-weight: bold;
}
@media screen and (max-width: 1199px) { 
  .payment-safe::before, .payment-safe::after {
    background-size: cover;
  }
}

@media screen and (max-width: 991px) { 
  .subocea-stats {
    padding-top: 20px;
  }
  .subocea-stats .stats-item__description {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 767px) { 
  .subocea-stats .stats-item__description .text {
    font-size: 14px;
    line-height: 18px;
  }
  .subocea-stats .stats-item .img-container {
    height: 100px;
    width: 100px;
  }
}
