@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap");

:root {
  --primary-red: #ED7208;
  --primary-red--dark-1: #b65704;
  --primary-green: #58b99d;
  --primary-dark-blue: #112E4A;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Raleway;
}
b {
  font-weight: bold;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.5rem;
}

.cta {
  padding: 0.875rem 1.5rem;
  border-radius: 3rem;
  background: var(--primary-red);
  color: white !important;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 300ms;
  font-size: 16px;
  display: block;
  width: fit-content;
}
button {
  border: none;
  background-color: transparent;
}
.m-auto {
  margin: auto;
}
.text--green {
  color: var(--primary-green) !important;
}
.cta:hover {
  background-color: var(--primary-red--dark-1);
}
.d-flex {
  display: flex;
}
hr.wavy {
  border: none;
}
hr.wavy:after {
  content: "";
  min-width: 91px;
  height: 12px;
  width: 100%;
  aspect-ratio: 6/1;
  display: block;
  margin-bottom: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='91' height='12' viewBox='0 0 91 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.03209 9.39708C8.24148 5.01288 24.7012 -1.63121 41.8135 4.23124C65.4642 12.3336 80.0411 11.0063 89.2627 6.42786' stroke='%23ED7208' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.by-subocea img {
  width: 45px;
  height: 7px;
  color: #ED7208;
}
.container--img {
  max-width: 1400px;
  margin: auto;
}
@media (min-width: 1250px) {
  .container {
    max-width: 1000px;
    margin: auto;
  }
}

@media (min-width: 750px) {
  .mobile-only {
    display: none !important;
  }
}
@media (max-width: 750px) {
  .desktop-only {
    display: none !important;
  }
}

@media (max-width: 1280px) {
  .desktop-only-map {
    display: none !important;
  }
}
