.nav__wrapper {
  top: 0;
  position: sticky;
  z-index: 10;
}
.navigation-menu {

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
}
#subocea-navigation {
  transition: color 400ms, background-color 400ms;
}

#subocea-navigation .logo{
  padding-block: 0.5rem;
  color: inherit;
}
#subocea-navigation.nav-filled .logo .cls-2,
#subocea-navigation.nav-filled .logo .cls-1-out{
  color: #112e4a;
}
body#pays .navigation-menu.nav-transparent .search-logo,
body#products .navigation-menu.nav-transparent .search-logo,
#subocea-navigation.nav-filled .search-logo,
#subocea-navigation.nav-filled .cls-3-text,
#subocea-navigation.nav-filled .logo .cls-1{
  color: #ed7208 !important;
  fill: #ed7208;
}
#subocea-navigation.nav-filled .cls-2-text{
  stroke:#ed7208;
}

body #subocea-navigation .logo{
  color:#112e4a;
}
body #subocea-navigation .logo .cls-1{
  color:#ed7208;
}
body .navigation-menu.nav-transparent .expedition-nav svg {
  fill:#ed7208;
}
body#pays .navigation-menu.nav-transparent .expedition-nav .cls-2-text,
body#products .navigation-menu.nav-transparent .expedition-nav .cls-2-text,
body .navigation-menu.nav-transparent .expedition-nav .cls-2-text{
  stroke:#ed7208;
}

body .navigation-menu.nav-transparent .expedition-nav .cls-3-text{
  fill:#ed7208;
}
body#pays .navigation-menu.nav-transparent .expedition-nav .cls-3-text,
body#pays .navigation-menu.nav-transparent .logo .cls-1,
body#products .navigation-menu.nav-transparent .expedition-nav .cls-3-text,
body#products .navigation-menu.nav-transparent .logo .cls-1{
  color:#ed7208;
  fill:#ed7208;
}
body#detail-actu .navigation-menu.nav-transparent .logo .cls-1,
body#leave .navigation-menu.nav-transparent .logo .cls-1,
body#home .navigation-menu.nav-transparent .logo .cls-1{
  color:white;
}
body#detail-actu .navigation-menu.nav-transparent .expedition-nav svg,
body#leave .navigation-menu.nav-transparent .expedition-nav svg,
body#home .navigation-menu.nav-transparent .expedition-nav svg {
  fill:white;
}
body#detail-actu .navigation-menu.nav-transparent .expedition-nav .cls-2-text,
body#leave .navigation-menu.nav-transparent .expedition-nav .cls-2-text,
body#home .navigation-menu.nav-transparent .expedition-nav .cls-2-text{
  stroke:white;
}
body#detail-actu .navigation-menu.nav-transparent .expedition-nav .cls-3-text,
body#leave .navigation-menu.nav-transparent .expedition-nav .cls-3-text,
body#home .navigation-menu.nav-transparent .expedition-nav .cls-3-text{
  fill:white;
}
#subocea-navigation.nav-filled {
  background-color: #fff;
  color: #000b1d;
}
.navigation-menu > ul {
  display: flex;
  flex: 1 1;
  justify-content: space-around;
}

.secondary-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: var(--primary-green);
  color: white;
}

.secondary-nav svg{
  margin-right: 0.5rem;
}

.secondary-nav .contribution  {
  display: flex;
  align-items: center;
}

.secondary-nav .contact  {
  display: flex;

}
.secondary-nav a{
  display: flex;
  align-items: center;
  margin-right: 1rem;
  color:inherit;
}

.navigation-menu > ul li,
.navigation-menu > ul a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}

.expedition-nav {
  flex-direction: column;
  position: relative;
}
.expedition-nav svg {
  transition: color 400ms;
  position: absolute;
  bottom: -100%;
  color: white;
}
@media screen and (max-width: 991px) {
  .expedition-nav svg {
    bottom: -6px;
  } 
}

#subocea-navigation.nav-filled .expedition-nav svg {
  color: var(--primary-red);
}

#destination-nav-item {
  cursor: pointer;
}

/* Mobile */

.burger-icon {
  color: inherit;
  width: 1.125rem;
  height: 1rem;
  border-top: 2px solid currentColor;
  position: relative;
  display: block;
  margin-right: 1rem;
  cursor: pointer;
}
.burger-icon:before,
.burger-icon:after {
  content: "";
  transition: width 300ms;
  position: absolute;
  height: 2px;
  width: 80%;
  top: 100%;
  left: 0;
  background-color: currentColor;
}

.burger-icon:before {
  width: 30%;
  top: calc(50% - 1px);
}
.burger-icon:hover:before,
.burger-icon:hover:after {
  width: 100%;
}

#destinations-mobile {
  position: fixed;
  padding: 20px;
  left: 0;
  top: 0;
  background-color: #fff;
  color: #333333;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 1rem);
  width: calc(100% - 1rem); */
  /* border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; */
  box-sizing: border-box;
  overflow: scroll;
}
#destinations-mobile hr {
  width: 99%;
  margin-left: 0;
}
#destinations-mobile.hidden {
  display: none;
}
details {
  display: block;
}

details > *:not(summary) {
  margin-left: 1.5rem;
  margin-block: 0.5rem;
}
details[open] > summary {
  background-color: #e5f0ee;
  color: var(--primary-green);
}
details[open] > summary:before {
  background-color: #e5f0ee;
}
details[open] > summary:after {
  transform: rotate(0deg);
}
li {
  list-style: none;
}
#destinations-mobile a {
  color: #333333;
  display: block;
  font-weight: bold;
}
#destinations-mobile .number {
  margin-top: 10px;
  margin-bottom: 5px;
}
#destinations-mobile > a {
  margin-left: 0;
}
#destinations-mobile summary::-webkit-details-marker,
#destinations-mobile summary::marker {
  display: none;
}

#destinations-mobile > li,
#destinations-mobile > a {
  padding: 1rem;
  position: relative;
  padding-left: 1.4rem;
}
#destinations-mobile > a svg {
  color: var(--primary-red);
}
summary {
  position: relative;
  padding: 0.5rem;
  font-weight: bold;
}

summary:before {
  content: "";
  background-color: white;
  height: 1.5rem;
  width: 1.25rem;
  position: absolute;
  left: 0;
}
summary:not(.secondLevel):after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 8.25L7.5 1.75L1 8.25' stroke='currentColor' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  height: 9px;
  width: 15px;
  display: inline-block;
  margin-left: 1rem;
  transform: rotate(180deg);
}

#destinations-mobile .mobile-menu-destination__item:hover {
  color: var(--primary-green);
}

/* Destination Navigation menu */
.region-nav-selector {
  padding-block: 1rem;
  background-color: var(--primary-green);
  box-shadow: -2rem 0 0 var(--primary-green), 2rem 0 0 var(--primary-green);
  color: white;
  margin-bottom: 1rem;
}

.region-nav-selector button {
  color: white;
}

.destination-nav-menu {
  position: fixed;
  height: fit-content;
  width: 100%;
  top: 110px;
  background-color: #fff;
  z-index: 4;
  padding: 2rem;
  padding-top: 0;

  box-sizing: border-box;
}
.destination-nav-menu:before {
  content: "";
  position: absolute;
  height: calc(100vh - 64px);
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #8080808f;
  z-index: 4;

  box-sizing: border-box;
}
.destination-nav-menu.hidden {
  display: none;
}

.who-nav-menu {
  position: fixed;
  height: fit-content;
  width: 100%;
  top: 110px;
  background-color: #fff;
  z-index: 4;
  padding: 2rem;
  padding-top: 0;

  box-sizing: border-box;
}
.who-nav-menu:before {
  content: "";
  position: absolute;
  height: calc(100vh - 64px);
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #8080808f;
  z-index: 4;

  box-sizing: border-box;
}
.who-nav-menu.hidden {
  display: none;
}


.region {
  display: flex;
  gap: 1rem;
}
.region h6 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;

  margin-bottom: 0.75rem;
  gap: 0.5rem;
}
.region > div {
  flex: 1 1;
}
.region .d-flex {
  gap: 1rem;
}
.region-image__container {
  max-width: 23vw;
  max-height: 250px;
  aspect-ratio: 1/1;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}
.region-image__container:after {
  content: "";
  background: linear-gradient(transparent, #0000008a);
  position: absolute;
  inset: 0;
}

.region-image__container .text {
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  align-items: center;
  color: white;
  bottom: 0;
  padding: 1rem;
  text-align: center;
}