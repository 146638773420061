.newsletter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 1rem 3rem 1rem;
  gap: 32px;

}
.newsletter__description {
  flex: 1;
}

.newsletter__description h4 {
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 24px;
  }
  .newsletter__description p {
    line-height: 1.5;
  }
.newsletter__form {
  height: fit-content;
  padding: 7px;
  border-radius: 2rem;
  border: 1px solid #dfdfe0;
  flex: 1;
}
.newsletter__form form {
  display: flex;
  align-items: center;
  flex: 1 1;
  gap: 0.5rem;
}
.newsletter__form input {
  flex: 1 1;
  border: none;
  margin-left: 1.5rem;
}
.newsletter__form button[type=submit]:not([class^=btn-dune-]) {
  padding: 14px 37px;
  background-color: #e5f0ee;
  color: var(--primary-green);
  border-radius: 1.5rem;
  border: 0;
  text-transform: none;
  font-weight: 700;
  font-size: 14px;
}

@media screen and (max-width: 767px) { 
  .newsletter {
    flex-direction: column;
    align-items: flex-start;
  }
}